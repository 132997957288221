.slick-dots {
  //top: -93px;
  position: relative;
  bottom: auto;
  width: auto;
  padding: 0;
  margin: 1rem 0 0;
  list-style: none;
  text-align: center;
  li {
    button {
      display: none;
    }
  }
}

.slick-slider {
  display: table;
  table-layout: fixed;
  width: 100%;
}

